import { StateCreator } from 'zustand'
import { IUserInformation } from '~/core/@types/global'
import { ToastSlice } from './toast-slice'

export interface UserSlice {
  user: IUserInformation
  setUser: (user: IUserInformation) => void
}

export const createUserSlice: StateCreator<
  ToastSlice & UserSlice,
  [],
  [],
  UserSlice
> = (set: Function) => ({
  user: {
    id: undefined,
    email: undefined,
    avatarVariants: undefined,
    fullName: undefined,
    phoneNumber: undefined,
    timezone: undefined,
    language: undefined,
    jobTitle: undefined,
    provider: undefined,
    fromProduct: undefined,
    emailVerified: undefined
  },
  setUser: (user: IUserInformation) => set({ user })
})
