import { create } from 'zustand'
import {
  createLoadingBlockAppSlice,
  LoadingBlockAppSlice
} from './loading-block-app-slice'
import { createMyListSlice, MyListSlice } from './my-list-slice'
import { createToastSlice, ToastSlice } from './toast-slice'
import { createUserSlice, UserSlice } from './user-slice'

const useBoundStore = create<
  LoadingBlockAppSlice & UserSlice & ToastSlice & MyListSlice
>()((...a) => ({
  ...createLoadingBlockAppSlice(...a),
  ...createUserSlice(...a),
  ...createToastSlice(...a),
  ...createMyListSlice(...a)
}))

export default useBoundStore
