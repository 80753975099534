import configuration from '~/configuration'
import { ICookies } from '~/core/@types/global'
import { getUserInfoByCookie } from '~/core/auth/get-user-info-by-cookie'
import { SESSION_COOKIE_USER } from '~/core/constants/cookies'

/**
 * @name redirectTo
 */
export function redirectTo({
  returnUrl,
  redirectPath
}: {
  returnUrl?: string
  redirectPath: string
}) {
  if (returnUrl) {
    const cleanReturnUrl = getPathFromReturnUrl(returnUrl)

    const queryParams = new URLSearchParams({
      returnUrl: cleanReturnUrl ?? configuration.path.default
    })

    // we build the sign in URL
    // appending the "returnUrl" query parameter so that we can redirect the user
    // straight to where they were headed and the "signOut" parameter
    // to force the client to sign the user out from the client SDK
    const destination = `${redirectPath}?${queryParams}`
    return {
      redirect: {
        permanent: false,
        destination
      }
    }
  } else {
    return {
      redirect: {
        permanent: false,
        destination: redirectPath
      }
    }
  }
}

export function getUserAuthentication(cookies: ICookies) {
  const authenticationToken = cookies[SESSION_COOKIE_USER]

  return getUserInfoByCookie({
    authCookie: authenticationToken
  })
}

export const handleAuthenticationFlow = ({
  user,
  resolvedUrl,
  query,
  redirectPath,
  extendedReturn
}: {
  user: ReturnType<typeof getUserAuthentication>
  // incomming request path
  resolvedUrl: string
  query: { [key: string]: string | string[] | undefined }
  redirectPath: string
  extendedReturn: object
}) => {
  try {
    // TODO: Auto pass url, we don't need to check validation here
    if (
      query.id &&
      [configuration.path.resumes.builderPreview(String(query.id))].includes(
        resolvedUrl
      )
    ) {
      return {
        props: {
          user,
          ...extendedReturn
        }
      }
    }

    if (!user) {
      return redirectTo({
        returnUrl: resolvedUrl,
        redirectPath
      })
    }

    return {
      props: {
        user,
        ...extendedReturn
      }
    }
  } catch (e) {
    // if the user is signed out, we save the requested URL
    // so, we can redirect them to where they originally navigated to
    return redirectTo({
      returnUrl: resolvedUrl,
      redirectPath
    })
  }
}

function getPathFromReturnUrl(returnUrl: string) {
  try {
    return new URL(returnUrl).pathname
  } catch (e) {
    return returnUrl.split('?')[0]
  }
}
