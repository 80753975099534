import { PUBLIC_APP_NAME } from '../core/constants/env'

const seoConfiguration = {
  500: `Error - ${PUBLIC_APP_NAME}`,
  400: `Not found - ${PUBLIC_APP_NAME}`,
  accessDenied: `Access Denied - ${PUBLIC_APP_NAME}`,
  authError: `Authentication Error - ${PUBLIC_APP_NAME}`,
  register: `Sign Up - ${PUBLIC_APP_NAME}`,
  login: `Sign In - ${PUBLIC_APP_NAME}`,
  verifyEmail: `Verify Email - ${PUBLIC_APP_NAME}`,
  myAccount: `My Account - ${PUBLIC_APP_NAME}`,
  termOfService: `Term of Services - ${PUBLIC_APP_NAME}`,
  privacyPolicy: `Privacy Policy - ${PUBLIC_APP_NAME}`,
  resumes: {
    editor: `Edit resume - ${PUBLIC_APP_NAME}`,
    dashboard: `My Dashboard - ${PUBLIC_APP_NAME}`
  }
}

export default seoConfiguration
