import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { useMemo } from 'react'
import { createClient, fetchExchange } from 'urql'
import configuration from '~/configuration'
import { SESSION_COOKIE_NAME } from '../constants/cookies'
import { DEFAULT_LOCALE } from '../constants/env'

/**
 * @name useGraphQLRequest
 * @description A hook to make queries language
 */
export function useGraphQLRequest<Resp = unknown, Body = void>() {
  const router = useRouter()
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const cookies = parseCookies()
  const authenticationToken = cookies[SESSION_COOKIE_NAME]

  const client = useMemo(
    () =>
      createClient({
        url: configuration.api.graphQL,
        exchanges: [fetchExchange],
        fetchOptions: () => {
          return {
            headers: {
              authorization: `Bearer ${authenticationToken}`,
              language: router.locale || DEFAULT_LOCALE,
              timezone: timeZone
              // 'Content-Type': 'application/json'
            }
          }
        }
      }),
    [authenticationToken, router.locale]
  )

  return client
}
