import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import configuration from '~/configuration'
import {
  getUserAuthentication,
  handleAuthenticationFlow,
  redirectTo
} from './auth-utilities'

const withClientAuthenticationMiddleware = <T extends { pageProps: object }>(
  Component: (props: any) => ReactElement,
  matcher: string[] = []
) => {
  const WithClientAuthenticationMiddleware = <F extends T>(props: F) => {
    const router = useRouter()
    const [suspend, setSuspend] = useState(true)
    const isMatch = useMemo(
      () => matcher.find((pattern) => router.asPath.match(pattern)),
      [router]
    )
    useEffect(() => {
      if (router.isReady) {
        if (isMatch) {
          const cookies = Cookies.get()
          const flowResult = handleAuthenticationFlow({
            user: getUserAuthentication(cookies),
            resolvedUrl: router.asPath,
            query: router.query,
            redirectPath: configuration.path.login,
            extendedReturn: {}
          })
          const redirectResponse = flowResult as ReturnType<typeof redirectTo>
          if (redirectResponse.redirect) {
            router.push(redirectResponse.redirect.destination)
          } else {
            setSuspend(false)
          }
          router.beforePopState(({ url, as, options }) => {
            // I only want to allow these two routes!
            const flowResult = handleAuthenticationFlow({
              user: getUserAuthentication(cookies),
              resolvedUrl: as,
              query: router.query,
              redirectPath: configuration.path.login,
              extendedReturn: {}
            })
            if (redirectResponse.redirect) {
              const redirectResponse = flowResult as ReturnType<
                typeof redirectTo
              >
              window.location.href = redirectResponse.redirect.destination
              return false
            }
            return true
          })
        } else {
          setSuspend(false)
        }
      }
    }, [router])

    return suspend && isMatch ? (
      <></>
    ) : (
      <Component {...props} showOnServer={!isMatch} />
    )
  }
  return WithClientAuthenticationMiddleware
}
export default withClientAuthenticationMiddleware
