'use client'

import IconWrapper from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'

const closeButtonVariants = cva('flex items-center outline-none', {
  variants: {
    disabled: {
      default:
        'text-gray-500 hover:text-primary-600 focus:text-primary-600 dark:text-gray-400 dark:hover:text-primary-300 dark:focus:text-primary-300',
      disabled: 'pointer-events-none text-gray-400 dark:text-gray-600'
    }
  },
  defaultVariants: {
    disabled: 'default'
  }
})

interface CloseButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled?: boolean
  iconClassName?: string
}

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ isDisabled = false, onClick, iconClassName = '' }, ref) => {
    return (
      <button
        type="button"
        ref={ref}
        className={cn(
          closeButtonVariants({ disabled: isDisabled ? 'disabled' : 'default' })
        )}
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}>
        <IconWrapper className={iconClassName} name="X" />
      </button>
    )
  }
)

CloseButton.displayName = 'CloseButton'

export { CloseButton }
export type { CloseButtonProps }
