export const ERROR_500_URL = '/500'
export const ERROR_404_URL = '/404'
export const ERROR_ACCESS_DENIED_URL = '/access-denied'
export const REGISTER_URL = '/register'
export const LOGIN_URL = '/login'
export const VERIFY_EMAIL_URL = '/verify-email'
export const AUTH_ERROR_URL = '/auth/error'
export const DEFAULT_URL = '/'

export const TERM_OF_SERVICES = '/term-of-services'
export const PRIVACY_POLICY = '/privacy-policy'
export const URL_TERM_OF_SERVICE = `https://freec-cms.directus.app/items/resumee_term_and_service`
export const URL_PRIVACY_POLICY = `https://freec-cms.directus.app/items/resumee_privacy_policy`

export const RESUMES_DASHBOARD_URL = '/resumes'
export const RESUMES_EDITOR_URL = (id: string) => `/resumes/${id}/editor`
export const RESUMES_BUILDER_URL = (id: string) => `/resumes/${id}/builder`
export const RESUMES_BUILDER_PREVIEW_URL = (id: string) =>
  `/resumes/${id}/builder-preview`
export const SETTINGS_LIST_URL = '/settings'
export const MY_ACCOUNT_URL = '/my-account'

export const SHARE_CV_URL = (id?: string) => `/share/cv/${id}`
