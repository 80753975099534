import { ReactElement, useEffect, useMemo, useState } from 'react'

import Cookies from 'js-cookie'
import { SESSION_COOKIE_USER } from '~/core/constants/cookies'
import { getUserAuthentication } from './auth-utilities'

const withClientSidePagePropsUser = <
  T extends { pageProps: object; showOnServer?: boolean }
>(
  Component: (props: T) => ReactElement
) => {
  const WithPagePropUser = <A extends T>(props: A) => {
    const [user, setUser] = useState<ReturnType<
      typeof getUserAuthentication
    > | null>()
    const cookies = Cookies.get() || {}
    useEffect(() => {
      setUser(getUserAuthentication(cookies) || null)
    }, [cookies[SESSION_COOKIE_USER]])
    const pageProps = useMemo(
      () => ({ ...props.pageProps, user }),
      [user, props.pageProps]
    )
    return user !== undefined || props.showOnServer ? (
      <Component {...props} pageProps={pageProps} />
    ) : (
      // it must be an app skeleton without user infomation
      <></>
    )
  }
  return WithPagePropUser
}

export default withClientSidePagePropsUser
