import Gleap from 'gleap'
import { NextRouter } from 'next/router'
import { destroyCookie } from 'nookies'
import configuration from '../../configuration'
import { IFormAction, IToast } from '../@types/global'
import {
  COOKIE_PATH,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_USER,
  SESSION_CSRF_SECRET_COOKIE,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '../constants/cookies'

export const LIST_KEYS_TENANT_BLOCKED = [
  'BLOCKED_TENANT_ERROR',
  'BLOCKED_ALL_TENANT_ERROR'
]

/**
 * @description destroy session cookies to sign user out
 * @param res
 */
export function destroySessionCookiesClient() {
  const options = { path: COOKIE_PATH }

  if (Gleap && Gleap.getInstance().initialized === true) {
    Gleap.clearIdentity()
  }

  destroyCookie(null, SESSION_COOKIE_NAME, options)
  destroyCookie(null, SESSION_COOKIE_USER, options)
  destroyCookie(null, SESSION_EXPIRES_AT_COOKIE_NAME, options)
  destroyCookie(null, SESSION_CSRF_SECRET_COOKIE, options)
}

export const catchErrorFromGraphQL = ({
  error,
  page,
  callback,
  formAction,
  setToast,
  router,
  callbackHandleStatusError422
}: {
  error: { graphQLErrors: Array<object>; networkError?: object }
  page?: string
  callback?: (errorText: string) => void
  formAction?: IFormAction
  setToast?: (state: IToast) => void
  router?: NextRouter
  callbackHandleStatusError422?: (
    keys: Array<{ field: string; message: string }>,
    blockedErrorCode?: string
  ) => void
}) => {
  const { graphQLErrors, networkError } = error

  if (networkError) {
    if (setToast) {
      setToast({
        open: true,
        type: 'error',
        title: 'Network Error'
      })
    }

    return null
  }

  if (graphQLErrors.length === 1) {
    const parseErrors = JSON.parse(JSON.stringify(graphQLErrors[0]))
    const keys =
      parseErrors?.message && typeof parseErrors?.message === 'string'
        ? parseErrors?.extensions?.errors
        : parseErrors?.message?.extensions?.errors
    const statusCode =
      parseErrors?.message && typeof parseErrors?.message === 'string'
        ? parseErrors?.extensions?.http_code
        : parseErrors?.message?.extensions?.http_code

    let blockedErrorCode =
      parseErrors?.extensions?.error_code ||
      parseErrors?.message?.extensions?.error_code

    if (statusCode === 401) {
      destroySessionCookiesClient()
      if (setToast) {
        setToast({
          open: true,
          type: 'info',
          title: 'You need to login again.'
        })
      }

      return setTimeout(() => {
        window.location.href = configuration.path.login
      }, 1500)
    }

    if (statusCode === 403) {
      if (setToast) {
        setToast({
          open: true,
          type: 'error',
          title:
            'You do not have permission to access the requested page. Please contact your system administrator for further assistance.'
        })
      }

      if (router) {
        router.push(configuration.path.errorAccessDenied)
      } else {
        window.location.href = configuration.path.errorAccessDenied
      }
    }

    if (statusCode === 404) {
      if (router) {
        router.push(configuration.path.error404)
      } else {
        window.location.href = configuration.path.error404
      }
    }

    if (statusCode === 500) {
      if (setToast) {
        setToast({
          open: true,
          type: 'error',
          title: 'Network Error'
        })
      }
    }

    if (statusCode === 422) {
      if (callbackHandleStatusError422) {
        callbackHandleStatusError422(keys, blockedErrorCode)
        return
      }

      keys.forEach((session: { field: string; message: string }) => {
        if (
          session.field &&
          formAction &&
          Object.keys(formAction).length &&
          formAction.control._fields[session.field]
        ) {
          formAction.setError(session.field, {
            type: 'custom',
            message: String(session.message)
          })
        } else if (setToast) {
          setToast({
            open: true,
            type: 'error',
            title: session.message
          })
        }
      })
    }
  }

  return null
}

export const catchErrorFromRequest = ({
  error,
  formAction
}: {
  error?: unknown | { errors: Array<{ extensions: object }> }
  formAction?: IFormAction
}) => {
  if (error && Object.keys(error).length) {
    // @ts-expect-error
    const errors = error?.errors

    if (errors?.length && formAction && Object.keys(formAction).length) {
      const parseErrors = JSON.parse(JSON.stringify(errors[0]))
      const keys = parseErrors?.extensions?.errors || []

      keys.forEach((session: { field: string; message: string }) => {
        formAction.setError(session.field, {
          type: 'custom',
          message: [session.message]
        })
      })
    } else {
      console.error(error)
    }
  }

  return null
}
