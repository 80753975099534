import {
  GRAPHQL_API,
  LOGIN_API,
  LOGOUT_API,
  SIGN_OUT_API,
  VERIFY_RECAPTCHA_API
} from '../core/constants/api'
import { PUBLIC_API_URL, PUBLIC_ENABLE_API_PROXY } from '../core/constants/env'

const apiConfiguration = {
  domain: PUBLIC_API_URL,
  login: `${PUBLIC_API_URL}${LOGIN_API}`,
  logout: `${PUBLIC_API_URL}${LOGOUT_API}`,
  signOut: SIGN_OUT_API,
  verifyReCaptcha: VERIFY_RECAPTCHA_API,
  graphQL: `${PUBLIC_ENABLE_API_PROXY ? '' : PUBLIC_API_URL}${GRAPHQL_API}`
}

export default apiConfiguration
