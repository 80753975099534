import { StateCreator } from 'zustand'

export interface MyListSlice {
  refetchMyList: boolean
  setRefetchMyList: (selected: boolean) => void
}

export const createMyListSlice: StateCreator<
  MyListSlice,
  [],
  [],
  MyListSlice
> = (set: Function) => ({
  refetchMyList: false,
  setRefetchMyList: (selected: boolean) => set({ refetchMyList: selected })
})
