import logger from '~/core/logger'
import { Maybe } from '../@types/global'

/**
 * @description Serializes safely the user object
 * @param cookie
 */
export function getUserInfoByCookie({ authCookie }: { authCookie: string }) {
  if (!authCookie) return null

  const user = JSON.parse(authCookie)
  try {
    return {
      id: getValue(user?.id),
      email: getValue(user?.email),
      fullName: getValue(user?.fullName),
      phoneNumber: getValue(user?.phoneNumber),
      jobTitle: getValue(user?.jobTitle),
      provider: getValue(user?.provider),
      fromProduct: getValue(user?.fromProduct),
      language: getValue(user?.language),
      timezone: getValue(user?.timezone),
      emailVerified: getValue(user?.emailVerified),
      avatarVariants: user?.avatarVariants,
      defaultColour: getValue(user?.defaultColour)
    }
  } catch (e) {
    logger.warn(
      {
        userId: user.id
      },
      `User was not found`
    )

    return
  }
}

/**
 * @description Guards against undefined values
 * @param value
 */
function getValue<T>(value: Maybe<T>) {
  return value ?? null
}
