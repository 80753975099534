// import Gleap from 'gleap'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Manrope as HeadingFont, Inter as InterFont } from 'next/font/google'
import { useCallback, useEffect } from 'react'
import { Provider } from 'urql'
// import configuration from '~/configuration'
import { IUserInformation, Maybe } from '~/core/@types/global'
import { SESSION_VERSION } from '~/core/constants/cookies'
import { useGraphQLRequest } from '~/core/hooks/use-graphQL'
import { Toast as ToastWrapper } from '~/core/ui/Toast'
import { destroySessionCookiesClient } from '~/core/utilities/catch-api-error'
import withClientAuthenticationMiddleware from '~/lib/next/with-client-authentication-middleware'
import withClientSidePagePropsUser from '~/lib/next/with-page-props-user'
import useBoundStore from '~/lib/store'
import '../src/styles/globals.css'
import configuration from '~/configuration'
import Gleap from 'gleap'

const AppRouteLoadingIndicator = dynamic(
  () => import('~/core/ui/AppRouteLoadingIndicator'),
  {
    ssr: false
  }
)

const AppRouteLoadingBlockApp = dynamic(
  () => import('~/core/ui/AppRouteLoadingBlockApp'),
  {
    ssr: false
  }
)

const fontFamilySans = InterFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: ['system-ui', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial'],
  preload: true,
  weight: ['300', '400', '500', '600', '700', '800']
})

const fontFamilyHeading = HeadingFont({
  subsets: ['latin'],
  variable: '--font-family-heading',
  fallback: ['--font-family-sans'],
  preload: true,
  weight: ['400', '500']
})

interface DefaultPageProps extends SSRConfig {
  session?: Maybe<Session>
  user?: Maybe<IUserInformation>
}

function App(
  props: AppProps<DefaultPageProps> & { pageProps: DefaultPageProps }
) {
  const { Component } = props
  const pageProps = props.pageProps as DefaultPageProps
  const setUser = useBoundStore((state) => state.setUser)
  const configToast = useBoundStore((state) => state.configToast)
  const setToastClose = useBoundStore((state) => state.setToastClose)
  const clearToast = useBoundStore((state) => state.clearToast)
  const showLockApp = useBoundStore((state) => state.showLockApp)
  const titleLockApp = useBoundStore((state) => state.titleLockApp)
  const client = useGraphQLRequest()

  if (
    configuration.key.gleapApiKey &&
    Gleap &&
    Gleap.getInstance().initialized === false &&
    pageProps.user &&
    Object.keys(pageProps.user).length &&
    pageProps.user.id
  ) {
    Gleap.initialize(configuration.key.gleapApiKey)
    Gleap.identify(pageProps.user.id, {
      name: pageProps.user?.fullName,
      email: pageProps.user?.email
    })
  }

  const updateCurrentUser = useCallback(() => {
    if (pageProps.user) {
      setUser(pageProps.user)
    }
  }, [setUser, pageProps?.user])

  useEffect(updateCurrentUser, [updateCurrentUser])

  // Function to clear complete cache data

  const clearCacheData = () => {
    if (window) {
      const version = SESSION_VERSION

      var storageVersion = localStorage.getItem('version')
      if (!storageVersion || storageVersion !== version) {
        sessionStorage.clear()
        localStorage.clear()
        destroySessionCookiesClient()

        setTimeout(() => {
          window.location.reload()
          localStorage.setItem('version', version)
        }, 250)
      }
    }
  }

  useEffect(() => {
    clearCacheData()
  }, [])

  return (
    <Provider value={client}>
      <SessionProvider session={pageProps.session}>
        <FontFamily />
        <Component {...pageProps} />

        <ToastWrapper
          {...configToast}
          setOpen={() => {
            setToastClose()
            setTimeout(() => {
              clearToast()
            }, 100)
          }}
        />

        {showLockApp ? <AppRouteLoadingBlockApp title={titleLockApp} /> : null}
      </SessionProvider>
    </Provider>
  )
}
export default appWithTranslation(
  withClientAuthenticationMiddleware(withClientSidePagePropsUser(App), [
    '^/((?!login|register|verify-email|auth|400|500|access-denied|share|term-of-services|privacy-policy).)*$'
  ])
)

function FontFamily() {
  return (
    <style jsx global>
      {`
        html {
          --font-family-sans: ${fontFamilySans.style.fontFamily};
          --font-family-heading: ${fontFamilyHeading.style.fontFamily};
        }
      `}
    </style>
  )
}
