/**
 * @name Enum
 */

export enum HttpStatusCode {
  Ok = 200,
  MovedPermanently = 301,
  MovedTemporarily = 302,
  SeeOther = 303,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  InternalServerError = 500
}

/**
 * @name String Enum
 */

export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_MOUNT_PAGE_SIZE = 10
export const DEFAULT_LOADING_PAGE_SIZE = 5
export const DEFAULT_ASYNC_LOADING_OPTIONS = 15
