import { PUBLIC_APP_URL } from '~/core/constants/env'
import {
  AUTH_ERROR_URL,
  ERROR_404_URL,
  ERROR_500_URL,
  ERROR_ACCESS_DENIED_URL,
  LOGIN_URL,
  MY_ACCOUNT_URL,
  PRIVACY_POLICY,
  REGISTER_URL,
  RESUMES_BUILDER_PREVIEW_URL,
  RESUMES_BUILDER_URL,
  RESUMES_DASHBOARD_URL,
  RESUMES_EDITOR_URL,
  SETTINGS_LIST_URL,
  SHARE_CV_URL,
  TERM_OF_SERVICES,
  VERIFY_EMAIL_URL
} from '../core/constants/url'

const pathConfiguration = {
  error500: ERROR_500_URL,
  error404: ERROR_404_URL,
  errorAccessDenied: ERROR_ACCESS_DENIED_URL,
  default: RESUMES_DASHBOARD_URL,
  register: REGISTER_URL,
  login: LOGIN_URL,
  verifyEmail: VERIFY_EMAIL_URL,
  authError: AUTH_ERROR_URL,
  termsOfUse: TERM_OF_SERVICES,
  privacyPolicy: PRIVACY_POLICY,
  resumes: {
    dashboard: RESUMES_DASHBOARD_URL,
    detail: (id: string) => RESUMES_EDITOR_URL(id),
    builder: (id: string) => RESUMES_BUILDER_URL(id),
    builderPreview: (id: string) => RESUMES_BUILDER_PREVIEW_URL(id)
  },
  settings: {
    list: SETTINGS_LIST_URL
  },
  myAccount: MY_ACCOUNT_URL,
  shareCV: (id?: string) => `${PUBLIC_APP_URL}${SHARE_CV_URL(id)}`
}

export default pathConfiguration
